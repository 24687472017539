import React from 'react'
import '../../App.css'
import Admin_pair from '../Admin_pair'

export default function Admin_pairing() {
  return (
    <>
      <Admin_pair></Admin_pair>
    </>
  )
}
