import React, { useState, useEffect } from 'react'
import '../../App.css'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Prog from '../Programusage'

export default function Programusage() {
  return (
    <>
      <Navbar></Navbar>
      <Prog></Prog>
      <Footer></Footer>
    </>
  )
}
