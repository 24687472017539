import React from 'react'
import '../../App.css'
import Admin_booking from '../Admin_booking'

export default function Admin_bookings() {
  return (
    <>
      <Admin_booking></Admin_booking>
    </>
  )
}
