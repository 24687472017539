import React from 'react'
import '../../App.css'
import Admin_user from '../Admin_user'

export default function Admin_users() {
  return (
    <>
      <Admin_user></Admin_user>
    </>
  )
}
