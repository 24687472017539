import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import Navbar from '../Navbar'
import App from '../Appointmentmeet'

export default function Appointment() {

  return (
    <>
      <Navbar></Navbar>
      <App></App>
      <Footer></Footer>
    </>
  )
}
