import React from 'react'
import '../../App.css'
import SignIn_design from '../SignIn_design'
import Navbar from '../Navbar'

export default function SignIn() {

  return (
    <>
      <Navbar></Navbar>
      <SignIn_design></SignIn_design>
    </>
  )
}
