import React from 'react'
import '../../App.css'
import Register_pageall from '../Register_pageall'
export default function Register() {
  return (
    <>
      <Register_pageall></Register_pageall>
    </>
  )
}
