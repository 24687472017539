import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import Navbar from '../Port_nav'
import Port from '../Portfolio'

export default function Student_portfolio() {
  return (
    <>
      <Navbar></Navbar>
      <Port></Port>
      <Footer></Footer>
    </>
  )
}
