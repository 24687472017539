import React from 'react'
import '../../App.css'
import Admin_appointment from '../Admin_appointment'

export default function SignIn() {
  return (
    <>
      <Admin_appointment></Admin_appointment>
    </>
  )
}
